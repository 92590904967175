<template>
  <div class="container-fluid">
    <div>
      <div class="login-box">
        <img src="@/assets/image/login/login.png" alt="" style="width: 424px;height: 718px;border-radius: 20px 0 0 20px">
        <div class="login-body">
          <div class="login-in">
            <img src="@/assets/image/login/logo.png" alt="" style="width: 274px;height: 62px">
            <div class="login-first">{{ $t('message.login') }}</div>
            <div v-if="isError" class="alert alert-danger" role="alert">{{ errormsg }}</div>
            <div class="login-two">
              <div>Username</div>
              <input v-model="loginForm.UserName" :placeholder="$t('message.username')" type="text" class="form-control">
            </div>
            <div class="login-three">
              <div>
                <div>Password</div>
                <div class="hand" @click="handleForgetPwd">Forgot Password ?</div>
              </div>
              <input v-model="loginForm.Password" :placeholder="$t('message.password')" type="password" class="form-control">
            </div>
            <div class="login-four">
              <input v-model="remember" type="checkbox">
              <span>{{ $t('message.remeberme') }}</span>
            </div>
            <div class="login-five">
              <div class="hand"  @click.prevent="handleLogin">{{ $t('message.login') }}</div>
              <div><router-link to="/BTCInfo">Cancel and return to website</router-link></div>
            </div>
            <div class="login-six">
              <div>
                <span>Not a member ?</span>
                <span><router-link to="/Register">Register now</router-link></span>
              </div>
              <div style="margin-left: 10px">
                <span>Terms Privacy Security ?</span>
                <span><router-link to="/ContactUs">Get In Touch</router-link></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import commonmix from '@/utils/commonmix'
import { setLocalStore, getLocalStore } from '@/utils/common'

export default {
  mixins: [commonmix],
  name: 'BTCest',
  components: {},
  data () {
    return {
      errormsg: '',
      loginForm: {
        UserName: '',
        Password: ''
      },
      logined: false,
      isError: false,
      remember: false,
      captcha: null,
      timer: null
    }
  },
  computed: {
    ...mapGetters([
      'name',
      'token',
      'userow'
    ])
  },
  updated () {
    // 回车登录
    document.onkeyup = (e) => {
      if (e.keyCode === 13 && (!this.logined && (!getLocalStore().username && !getLocalStore().password))) {
        this.handleLogin()
      }
    }
  },
  mounted () {
  },
  methods: {
    handleLogin () {
    // 登录
      this.$store.dispatch('Login', this.loginForm).then(() => {
        this.$store.dispatch('GetUserProfile', this.loginForm.UserName).then(() => {
          this.logined = true
          this.isError = false
          // 登录成功保存Token
          if (this.remember) {
            setLocalStore({ token: this.token })
          }
          this.getUserProfile()
          this.getUserInfo()
          this.$router.push('/BTCInfo')
        })
        this.$store.dispatch('UpdateUserActivityByToken', this.token)
      }).catch((err) => {
        this.isError = true
        if (err.ErrorCode === 6009) {
          this.errormsg = this.$t('message.userpwdnotmatch')
        } else if (err.ErrorCode === 60020) {
          this.errormsg = this.$t('message.userdelete')
        } else if (err.ErrorCode === 60021) {
          this.errormsg = this.$t('message.enfrozen')
        }
      })
    },
    // 忘记密码
    handleForgetPwd () {
      this.$bvModal.show('forgot-password-modal')
    },
    getUserProfile () {
      this.$store.dispatch('GetUserProfile', getLocalStore().UserID)
    },
    getUserInfo () {
      this.$store.dispatch('GetFrontUserInfoByUserID', { UserID: getLocalStore().UserID })
    }
  }
}
</script>

<style lang="scss" scoped>

.container-fluid{
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
}

.container-fluid > div{
  width: 1200px;
  padding: 0;
  background: #FFFFFF;
  border-radius: 20px;
  margin: 20px 0;
}

@media screen and (min-width: 2048px) {
  .container-fluid > div{
    margin: 60px 0;
  }
}

/*占位符修改*/
input::-webkit-input-placeholder { /* WebKit browsers */
  font-size: 10px;
  color: #CCCCCC;
}

input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  font-size: 10px;
  color: #CCCCCC;
}

input::-moz-placeholder { /* Mozilla Firefox 19+ */
  font-size: 10px;
  color: #CCCCCC;
}

input:-ms-input-placeholder { /* Internet Explorer 10+ */
  font-size: 10px;
  color: #CCCCCC;
}
</style>
